<template>
  <b-card>
    <b-static-table
      :items="mappedClients"
      :fields="clientsTableFields"
      :loading="loading"
      :button-text="$t('Nou client')"
      show-button
      show-search
      @button-click="onNewClientButtonClicked"
      @item-click="onClientClicked"
      @item-middle-click="onClientMiddleClicked"
    />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import BStaticTable from "@foravila-core/components/b-static-table/BStaticTable.vue";
import { getFullAddress, notifyError } from "@/utils/methods";

export default {
  components: {
    BCard,
    BStaticTable,
  },
  data() {
    return {
      clientsTableFields: [
        { key: "firstName", label: this.$t("Nom"), sortable: true },
        { key: "lastName", label: this.$t("Llinatges"), sortable: true },
        { key: "phone", label: this.$t("Telèfon"), sortable: true },
        { key: "email", label: this.$t("Email"), sortable: true },
        { key: "address", label: this.$t("Address"), sortable: true },
        { key: "document", label: this.$t("Document"), sortable: true },
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.getters["clients/loading"];
    },
    clients() {
      return this.$store.getters["clients/clients"];
    },
    mappedClients() {
      if (!this.clients?.length) return [];
      return this.clients.map((client) => ({
        uuid: client.uuid || this.$t("No definit"),
        firstName: client.firstName || this.$t("No definit"),
        lastName: client.lastName || this.$t("No definit"),
        phone: client.phone || this.$t("No definit"),
        email: client.email || this.$t("No definit"),
        address:
          getFullAddress(client.location, this.$i18n.locale) ||
          this.$t("No definit"),
        document: client.idNumber || this.$t("No definit"),
      }));
    },
  },
  created() {
    if (!this.clients?.length && !this.loading) this.fetchClients();
  },
  methods: {
    fetchClients() {
      this.$store
        .dispatch("clients/fetchClients", { pagination: false, sort: "asc" })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchClients.title"),
            this.$t("errors.fetchClients.description")
          )
        );
    },
    onNewClientButtonClicked() {
      this.$router.push({
        name: "foravila-new-client",
      });
    },
    onClientClicked(clientUuid) {
      this.$router.push({
        name: "foravila-client-view",
        params: { clientUuid },
      });
    },
    onClientMiddleClicked(clientUuid) {
      const routeData = this.$router.resolve({
        name: "foravila-client-view",
        params: { clientUuid },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
